<template>
  <b-row class="justify-content-center mb-4">
    <b-col md="4">
      <b-card class="shadow-sm">
        <b-list-group>

          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <span>Informations sur la société</span>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Responsable</h6>
            {{ societe.manager === null?'Non défini': societe.manager.name }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Raison sociale</h6>
            {{ societe.company_name }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Email</h6>
            {{ societe.email }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Téléphone</h6>
            {{ societe.phone }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Siège social</h6>
            {{ societe.headquarter }}
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>IFU</h6>
            {{ societe.ifu }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>RCC</h6>
            {{ societe.trade_register }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Catégorie</h6>
            {{ societe.categorie_societe.title }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Etat</h6>
            <b-badge
              pill
              :variant="`light-${statusSocieteVariant(societe.status)}`"
            >
              {{ societe.status }}

            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-card>

    </b-col>
    <b-col md="8">
      <div class="bg-light shadow-lg p-1 rounded mb-2">
        Historique des responsables
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="societe.status === 'Actif' ? 'danger' : 'success'"
          size="sm"
          pill
          class="float-right mb-1"
          @click.prevent="changerSocieteStatus"
        >
          {{ societe.status==='Actif'?'Suspendre':'Activer' }}  la société
          <feather-icon icon="AlertTriangleIcon" />
        </b-button>
      </div>

      <b-card
        no-body
        class="card-company-table"
      >
        <b-table
          :items="tableData"
          responsive
          :fields="fields"
          class="mb-0"
          show-empty
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center">
              <b-img
                fluid
                :style="{width:'50%'}"
                :src="require('@/assets/images/no_result.gif')"
              />
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(roles)="data">

            <b-badge
              v-for="item in data.item.roles"
              :key="`jour-${item.id}`"
              pill
              class="ml-1"
              variant="light-primary"
            >
              {{ item.libelle }}

            </b-badge>
          </template>

        </b-table>
      </b-card>
      <div class="bg-light shadow-lg p-1 rounded mb-2">
        Copropriétés
      </div>

      <b-card
        no-body
        class="card-company-table"
      >
        <b-table
          :items="tableData"
          responsive
          :fields="fields"
          class="mb-0"
          show-empty
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center">
              <b-img
                fluid
                :style="{width:'50%'}"
                :src="require('@/assets/images/no_result.gif')"
              />
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(roles)="data">

            <b-badge
              v-for="item in data.item.roles"
              :key="`jour-${item.id}`"
              pill
              class="ml-1"
              variant="light-primary"
            >
              {{ item.libelle }}

            </b-badge>
          </template>

        </b-table>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  BCard, BBadge, BRow, BCol, BListGroup, BListGroupItem, BTable, BSpinner, BImg,
  BButton,

} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useSocietes from '@/composables/societeService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BTable,
    BSpinner,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },

  setup() {
    const {
      getSocieteById, societe, changeSocieteStatus,
    } = useSocietes()

    const tableData = ref([])
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'copropriete', label: 'Copropriété' },
      { key: 'roles', label: 'Rôles' },
    ]

    const statusSocieteVariant = status => {
      if (status === 'Actif') return 'success'
      if (status === 'Suspendu') return 'danger'
      return 'primary'
    }
    const changerSocieteStatus = async () => {
      Swal.fire({
        title: `${societe.value.status === 'Actif' ? 'Suspendre' : 'Lever la suspension'}`,
        text: `Etes-vous sûr de vouloir ${societe.value.status === 'Actif' ? 'suspendre' : 'lever la suspension de'} cette société ?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Valider',
      }).then(async result => {
        if (result.isConfirmed) {
          await changeSocieteStatus(router.currentRoute.params.id)
          await getSocieteById(router.currentRoute.params.id)
          Swal.fire({

            title: `${societe.value.status === 'Actif' ? 'Levée de suspension' : 'Suspension'}`,
            text: `Société ${societe.value.status === 'Actif' ? 'activée' : 'suspendue'} avec succès.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }

    onMounted(async () => {
      await getSocieteById(router.currentRoute.params.id)
    })

    return {
      tableData, fields, societe, statusSocieteVariant, changerSocieteStatus,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
