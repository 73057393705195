import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
// eslint-disable-next-line import/no-cycle

export default function useSocietes() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const societe = ref([])
  const loader = ref(false)
  const societeProcess = ref(false)
  const societeSuccess = ref(false)

  const societes = ref([])
  const errors = ref('')

  const getAllSocietes = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-societes')
      if (response.data.success === true) {
        loader.value = false
        societes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getAllSocietesByCategorieSocieteId = async categorieSocieteId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-societes-by-categorie-societe-id/${categorieSocieteId}`)
      if (response.data.success === true) {
        loader.value = false
        societes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getSocieteById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-societe-by-id/${id}`)
      if (response.data.success === true) {
        societe.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const createSociete = async data => {
    try {
      errors.value = ''
      societeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-societe', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        societeSuccess.value = true
        societeProcess.value = false
        societe.value = response.data.data
        await getAllSocietes()
      }
    } catch (error) {
      societeProcess.value = false
      societeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateSociete = async data => {
    try {
      errors.value = ''
      societeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-societe/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        societeSuccess.value = true
        societeProcess.value = false
        societe.value = response.data.data
        await getAllSocietes()
      }
    } catch (error) {
      societeProcess.value = false
      societeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const changeSocieteStatus = async id => {
    try {
      errors.value = ''
      societeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/suspend-societe/${id}`)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        societeSuccess.value = true
        societeProcess.value = false
        societe.value = response.data.data
        await getAllSocietes()
      }
    } catch (error) {
      societeProcess.value = false
      societeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const addOrChangeSocieteManager = async data => {
    try {
      errors.value = ''
      societeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/add-manager/${data.societe_id}`, data)
      if (response.data.success === true) {
        successToast('Manager', response.data.message)

        societeSuccess.value = true
        societeProcess.value = false
        societe.value = response.data.data
        await getAllSocietes()
      }
    } catch (error) {
      societeProcess.value = false
      societeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const deleteSociete = async id => {
    try {
      errors.value = ''
      societeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.delete(`/delete-societe/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)

        societeSuccess.value = true
        societeProcess.value = false
        societe.value = response.data.data
        await getAllSocietes()
      }
    } catch (error) {
      societeProcess.value = false
      societeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    errors,
    societe,
    societes,
    societeProcess,
    loader,
    societeSuccess,
    getAllSocietes,
    getAllSocietesByCategorieSocieteId,
    getSocieteById,
    createSociete,
    updateSociete,
    deleteSociete,
    addOrChangeSocieteManager,
    changeSocieteStatus,

  }
}
